import React, { useState, useEffect } from 'react';
import Button from './Button';
import { initialScore, pointValues, getNextPointScore, getNextGameScore } from './tennisScoring';
import './App.css';
import smashProLogo from './img/smash-pro-logo.png';

const PLAYER_ONE_NAME = "Khizar";
const PLAYER_TWO_NAME = "Snowy";

function ScoreCard({ playerOneScore, playerTwoScore, gameDuration }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="score-card">
      <table>
        <thead>
          <tr>
            <th>{time.toLocaleTimeString()}</th>
            <th>Smash Pro</th>
            <th></th>
            <th>Time {gameDuration}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Player</td>
            <td>Sets</td>
            <td>Games</td>
            <td>Points</td>
          </tr>
          <tr>
            <td>{PLAYER_ONE_NAME}</td>
            <td>{playerOneScore.sets}</td>
            <td>{playerOneScore.games}</td>
            <td>{pointValues[playerOneScore.points]}</td>
          </tr>
          <tr>
            <td>{PLAYER_TWO_NAME}</td>
            <td>{playerTwoScore.sets}</td>
            <td>{playerTwoScore.games}</td>
            <td>{pointValues[playerTwoScore.points]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function App() {
  const [playerOneScore, setPlayerOneScore] = useState(initialScore);
  const [playerTwoScore, setPlayerTwoScore] = useState(initialScore);
  const [gameStartTime, setGameStartTime] = useState(null);
  const [gameDuration, setGameDuration] = useState("Counter");

  useEffect(() => {
    let intervalId;

    if (gameStartTime) {
      intervalId = setInterval(() => {
        const elapsed = new Date(new Date() - gameStartTime);
        setGameDuration(
          `${String(elapsed.getUTCHours()).padStart(2, '0')}:${String(elapsed.getUTCMinutes()).padStart(2, '0')}:${String(elapsed.getUTCSeconds()).padStart(2, '0')}`
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [gameStartTime]);

  useEffect(() => {
    // Ensure speech synthesis is supported
    if ('speechSynthesis' in window) {
      window.speechSynthesis.onvoiceschanged = () => {
        const voices = window.speechSynthesis.getVoices();
        // Choose a voice if needed, example: const voice = voices[0];
      };
    }
  }, []);

  const handleFirstPoint = () => {
    if (!gameStartTime) {
      setGameStartTime(new Date());
    }
  };

  const handlePlayerOneClick = () => {
    handleFirstPoint();

    setPlayerOneScore(prevPlayerOneScore => {
      let newPlayerOneScore = { ...prevPlayerOneScore };
      let newPlayerTwoScore = { ...playerTwoScore };

      const pointOutcome = getNextPointScore(prevPlayerOneScore.points, playerTwoScore.points);

      if (pointOutcome === 'game') {
        const gameOutcome = getNextGameScore(prevPlayerOneScore.games, prevPlayerOneScore.sets);
        newPlayerOneScore = { points: 0, games: gameOutcome.games, sets: gameOutcome.sets };
        newPlayerTwoScore.points = 0;
      } else if (pointOutcome === 'deuce') {
        newPlayerOneScore.points = 3;
        newPlayerTwoScore.points = 3;
      } else {
        newPlayerOneScore.points = pointOutcome === 'advantage' ? 'advantage' : pointOutcome;
      }

      // Update both scores
      setPlayerTwoScore(newPlayerTwoScore);

      // Speak the score after updating state
      speakScore(PLAYER_ONE_NAME, newPlayerOneScore, PLAYER_TWO_NAME, newPlayerTwoScore);

      return newPlayerOneScore;
    });
  };

  const handlePlayerTwoClick = () => {
    handleFirstPoint();

    setPlayerTwoScore(prevPlayerTwoScore => {
      let newPlayerOneScore = { ...playerOneScore };
      let newPlayerTwoScore = { ...prevPlayerTwoScore };

      const pointOutcome = getNextPointScore(prevPlayerTwoScore.points, playerOneScore.points);

      if (pointOutcome === 'game') {
        const gameOutcome = getNextGameScore(prevPlayerTwoScore.games, prevPlayerTwoScore.sets);
        newPlayerTwoScore = { points: 0, games: gameOutcome.games, sets: gameOutcome.sets };
        newPlayerOneScore.points = 0;
      } else if (pointOutcome === 'deuce') {
        newPlayerTwoScore.points = 3;
        newPlayerOneScore.points = 3;
      } else {
        newPlayerTwoScore.points = pointOutcome === 'advantage' ? 'advantage' : pointOutcome;
      }

      // Update both scores
      setPlayerOneScore(newPlayerOneScore);

      // Speak the score after updating state
      speakScore(PLAYER_ONE_NAME, newPlayerOneScore, PLAYER_TWO_NAME, newPlayerTwoScore);

      return newPlayerTwoScore;
    });
  };

  const speakScore = (playerOneName, playerOneScore, playerTwoName, playerTwoScore) => {
    const playerOnePoints = pointValues[playerOneScore.points];
    const playerTwoPoints = pointValues[playerTwoScore.points];

    let msgText;

    if (playerOneScore.points === 'advantage') {
      msgText = `${playerOneName} has advantage`;
    } else if (playerTwoScore.points === 'advantage') {
      msgText = `${playerTwoName} has advantage`;
    } else if (playerOneScore.points === 'game') {
      msgText = `${playerOneName} wins the game`;
    } else if (playerTwoScore.points === 'game') {
      msgText = `${playerTwoName} wins the game`;
    } else {
      msgText = `${playerOneName} ${playerOnePoints} - ${playerTwoName} ${playerTwoPoints}`;
    }

    const msg = new SpeechSynthesisUtterance(msgText);
    msg.volume = 1; // Volume range: 0-1
    msg.rate = 1; // Rate range: 0.1-10
    msg.pitch = 1; // Pitch range: 0-2

    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="app-container">
      <img src={smashProLogo} alt="Smash Pro Logo" className='smash-pro-logo' />
      <ScoreCard
        playerOneScore={playerOneScore}
        playerTwoScore={playerTwoScore}
        gameDuration={gameDuration}
      />
      
      <div className="button-container">
        <Button label={PLAYER_ONE_NAME} onClick={handlePlayerOneClick} />
        <Button label={PLAYER_TWO_NAME} onClick={handlePlayerTwoClick} />
      </div>
    </div>
  );
}

export default App;
