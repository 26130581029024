export const initialScore = {
    points: 0,
    games: 0,
    sets: 0,
  };
  
  export const pointValues = ['0', '15', '30', '40'];
  
  export function getNextPointScore(currentPoints, opponentPoints) {
    if (currentPoints === 3) {
      if (opponentPoints < 3) {
        return 'game';
      } else if (opponentPoints === 3) {
        return 'deuce';
      } else {
        return 'advantage';
      }
    } else if (currentPoints === 'advantage') {
      return 'game';
    } else {
      return currentPoints + 1;
    }
  }
  
  export function getNextGameScore(currentGames, currentSets) {
    if (currentGames === 5) {
      return { games: 0, sets: currentSets + 1 };
    } else {
      return { games: currentGames + 1, sets: currentSets };
    }
  }
  